@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'main'; //magnific-popup

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;
@font-face {
	font-family: 'planet_estyleregular';
	src: url('../fonts/plane___-webfont.eot');
	src: url('../fonts/plane___-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/plane___-webfont.woff') format('woff'),
		 url('../fonts/plane___-webfont.ttf') format('truetype'),
		 url('../fonts/plane___-webfont.svg#planet_estyleregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

.palmedia {
	font-family: "planet_estyleregular";
	font-size: 110%;
	color: $white;
	&:hover {
		color: $medium-gray;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
}

table.sportlink {
	font-size: .9rem;
}

.row-spacer {
	height: rem-calc(20);
}

.logo-shadow {
	-webkit-filter: drop-shadow( 0px 0px 4px rgba(0,0,0,.4) );
	filter: drop-shadow( 0px 0px 4px rgba(0,0,0,.4) );
}

.over-the-top-bar {
	li {
		a {
			padding: 0.7rem 0.5rem;
		}
	}
}
.social-icons {
	a {
		padding: 1rem 0.2rem;
	}
	margin-bottom: rem-calc(20);
}

.top-bar .menu {
	a {
		color: $white;
		&:hover {
			color: $light-gray;
		}
		&::after {
			border-top-color:  $white;
		}
	}
	.submenu {
		a {
			&:hover {
				background-color: darken($primary-color, 2);
			}
		}
	}
}

.menu-row {
	background-color: $primary-color;
}

.clubmenu {
	width: 100%;
	li {
		background-color: $primary-color;
		width: 100%;
		&:hover {
			background-color: scale-color($primary-color, $lightness: 15%);
		}
		a {
			color: $white;
			&:hover {
				color: $white;

			}
		}
	}
}


[data-dropdown-menu] ul { display: none; }

.no-js .top-bar { display: none; }

@media screen and (min-width: 50em) {

     .no-js .top-bar { display: block; }

     .no-js .title-bar { display: none !important; }

}

.menu-spacer {
	width: rem-calc(125);
	position: relative;
}
.menu-spacer .logo {
	width: rem-calc(129);
	position: absolute;
	top: rem-calc(-90);
	left: rem-calc(-15);
}

.next-match {
	padding-top: rem-calc(20);
	padding-bottom: rem-calc(10);
	background-color: $light-gray;
	color: $primary-color;
	.columns  {
		position: relative;
	}
	.text-header {
		font-size: rem-calc(14);
		margin-bottom: rem-calc(0);
	}
	.text-date {
		font-size: rem-calc(20);
	}
	.text-large {
		font-size: rem-calc(16);
	}
	.text-devider {
		margin-top: rem-calc(20);
		font-size: rem-calc(40);
		font-weight: bold;
	}
	.bal-sponsor {
		background-color: $white;
		padding: rem-calc(10);
	}
	.team-logo {
		width: rem-calc(75);
		margin-bottom: rem-calc(10);
	}
	.team-name {
		position: absolute;
		bottom: 0;
		width: 100%

	}
}

.footer {
	background-color: $primary-color;
	color: $white;
	font-size: rem-calc(14);
	line-height: rem-calc(19);
	padding: rem-calc(35) 0 rem-calc(20) 0;
	position: relative;
	.footer-spacer .logo {
		width: rem-calc(90);
		position: absolute;
		top: rem-calc(-50);
		left: rem-calc(75);
	}
}

body#kantine {
	h1 {
		margin-bottom: rem-calc(15);
	}
	.footer {
		line-height: rem-calc(10);
		padding: rem-calc(15) 0 rem-calc(15) 0;
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 50px; /* choose any height */
		.footer-spacer .logo {
			width: rem-calc(120);
			position: absolute;
			top: rem-calc(-130);
			left: rem-calc(20);
		}

	}
}

ul.topscorers {
	font-size: .9rem;
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;

}

ul.list2col {
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
}

ul.list3col {
	columns: 3;
	-webkit-columns: 3;
	-moz-columns: 3;
}

ul.list4col {
	columns: 4;
	-webkit-columns: 4;
	-moz-columns: 4;
}

.off-canvas.position-left {
	height: 100%;
	color: $white;
	a {
		color: $white;
		&:hover {
			color: $light-gray;
		}
	}
	.is-drilldown-submenu {
		height: 100%;
		margin: 0;
		li {
			margin: 0;
		}
	}
}

.title-bar {
	display: none;
}

tr.Own {
	font-weight: bolder;
}
.no-highlight tr.Own {
	font-weight: normal;
}

.hide-if-empty {
	display: none; 
}

@media #{$medium-only} {
	table {
		font-size: .8rem;
	}
}
@media #{$small-only} {
	.footer {
		.footer-spacer .logo {
			width: rem-calc(70);
			position: absolute;
			top: rem-calc(-40);
			left: auto;
		}
	}
	table {
		font-size: .8rem;
	}

	/* Teamspecifiek */
	/* Verberg Stand columns W G V DV DT DS mobile */
	.poulestand tr td:nth-child(4),
	.poulestand tr th:nth-child(4),
	.poulestand tr td:nth-child(5),
	.poulestand tr th:nth-child(5),
	.poulestand tr td:nth-child(6),
	.poulestand tr th:nth-child(6),
	.poulestand tr td:nth-child(8),
	.poulestand tr th:nth-child(8),
	.poulestand tr td:nth-child(9),
	.poulestand tr th:nth-child(9),
	.poulestand tr td:nth-child(10),
	.poulestand tr th:nth-child(10) {
	    display: none;
	}
	/* Verberg PeriodeStand columns W G V DV DT DS mobile */
	.poulestand tr td:nth-child(4),
	.poulestand tr th:nth-child(4),
	.poulestand tr td:nth-child(5),
	.poulestand tr th:nth-child(5),
	.poulestand tr td:nth-child(6),
	.poulestand tr th:nth-child(6),
	.poulestand tr td:nth-child(8),
	.poulestand tr th:nth-child(8),
	.poulestand tr td:nth-child(9),
	.poulestand tr th:nth-child(9),
	.poulestand tr td:nth-child(10),
	.poulestand tr th:nth-child(10) {
	    display: none;
	}
	/* Verberg kolom accomodatie bij programma mobile */
	.poule-programma tr td:nth-child(5),
	.poule-programma tr th:nth-child(5),
	.poule-programma tr td:nth-child(6),
	.poule-programma tr th:nth-child(6) {
	    display: none;
	}

	/* Clubbreed */
	/* Verberg programma kolommen boven de 4 */
	.programma tr td:nth-child(5),
	.programma tr th:nth-child(5),
	.programma tr td:nth-child(6),
	.programma tr th:nth-child(6),
	.programma tr td:nth-child(7),
	.programma tr th:nth-child(7),
	.programma tr td:nth-child(8),
	.programma tr th:nth-child(8),
	.programma tr td:nth-child(9),
	.programma tr th:nth-child(9),
	.programma tr td:nth-child(10),
	.programma tr th:nth-child(10),
	.programma tr td:nth-child(11),
	.programma tr th:nth-child(11),
	.programma tr td:nth-child(12),
	.programma tr th:nth-child(12)   {
	    display: none;
	}
	/* Verberg afgelastingen kolommen boven de 4 */
	/* tijdstip */
	.afgelastingen tr td:nth-child(2),
	.afgelastingen tr th:nth-child(2),
	.afgelastingen tr td:nth-child(5),
	.afgelastingen tr th:nth-child(5),
	.afgelastingen tr td:nth-child(6),
	.afgelastingen tr th:nth-child(6),
	/* Status nr. 7 wel tonen */
	.afgelastingen tr td:nth-child(8),
	.afgelastingen tr th:nth-child(8),
	.afgelastingen tr td:nth-child(9),
	.afgelastingen tr th:nth-child(9),
	.afgelastingen tr td:nth-child(10),
	.afgelastingen tr th:nth-child(10),
	.afgelastingen tr td:nth-child(11),
	.afgelastingen tr th:nth-child(11),
	.afgelastingen tr td:nth-child(12),
	.afgelastingen tr th:nth-child(12)   {
	    display: none;
	}

}


.nixhier
{
	display: none !important;
}      
